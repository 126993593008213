const discountTable = {
  "d": {
    "label": "Day(s)",
    "values": {
      "1": 0,
      "2": 0,
      "3": 5,
      "4": 6,
      "5": 8,
      "6": 10,
    }
  },
  "w": {
    "label": "Week(s)",
    "values": {
      "1": 15,
      "2": 20,
      "3": 25,
      "4": 30,
    }
  }
};

export default discountTable;
