const dictionaryEN = {
  originalWriting: 'English',
  general: {
    user: 'User',
    email: 'E-mail',
    password: 'Password',
    close: 'Close',
    save: 'Save',
    done: 'Done',
    accept: 'Accept',
    cancel: 'Cancel',
    decline: 'Decline',
    cut: 'Cut',
    browse: 'Browser',
    chooseFileLabel: 'Choose file...',
    selectedCountLabel: 'files selected',
    from: 'from',
    perDay: 'per day',
    select: 'Select',
    owner: 'Owner',
    details: 'Details',
    sizeList:[
      'None',
      'Small',
      'Medium',
      'Large'
    ],
    options: 'Options',
    status: 'Status',
    pay: 'Pay',
    edit: 'Edit',
    from: 'From',
    to: 'To',
    date: 'Date',
    place: 'Place',
    empty: 'Empty',
    full: 'Full',
    go: 'Go',
    uploadImagesLabel: 'Upload images (Max %%)',
    uploadListLimitMessage: 'You must select %% images to upload',
  },
  user: {
    login: {
      title: 'Login',
      userMailLabel: 'User or Email',
      userMailPlaceHolder: 'User name or email',
      userPasswordLabel: 'Password',
      userPasswordPlaceholder: 'Password',
      loginButtonLabel: 'Log In',
      newUserLabel: 'New Account',
      successfulLabel: 'You are currently logged'
    },
    newAccount: {
      titleDialog: 'Register new user',
      titleForm: 'New user',
      userNameLabel: 'User',
      userNamePlaceHolder: 'User name (min 8 characters)',
      userEmailLabel: 'E-mail',
      userEmailPlaceholder: 'E-mail',
      userPasswordLabel: 'Password',
      userPasswordAllowedCharsMessage: 'Allowed characters: Uppercase,lowercase, numbers and @$!%*?&',
      userPasswordPlaceholder: 'Password (min 8 characters)',
      userPasswordConfirmLabel: 'Password Confirm',
      userPasswordConfirmPlaceHolder: 'Password confirm',
      userAgreementCheckbox: 'I fully accept agreement',
      userAgreementViewDoc: 'View agreement',
      userMissingFieldsMessage: 'Some fields are missing, please fill required fields.',
      userMinLengthMessage: 'User name must be at least 8 characters.',
      userEmailValidMessage: 'Please, enter a valid email.',
      userPasswordValidationsMessage: 'Password is not strong enough. Must have at least 8 characters <br/> Must have at least 1 lower case letter.<br/>Must have at least 1 upper case letter<br/>Must have one number.<br/>It must have at least one of these characters: @$!%*?&',
      userPasswordMismatchMessage: 'Password mismatch.',
      userAcceptAgreementMessage: 'You must accept have read and accept our agreement terms.',
      userSuccesfullyCreatedMessage: 'User has been created. Please, open your email account and enable it.',
    },
    edit: {
      title: 'User Account Edition',
      tabs: [
        'User Account',
        'Personal',
        'Location',
        'Car Data',
      ],
      account: {
        avatarSelection: 'Avatar edition/selection',
        avatarUploadImage: 'Upload image',
        avatarSave: 'Save',
        uploadAvatarOkMessage: 'Avatar image successfully updated',
      },
      personal: {
        title: 'Personal Data',
        contactFirstName: 'First Name',
        contactLastName: 'Last Name',
        contactGenderLabel: 'Gender',
        contactGenderMale: 'Male',
        contactGenderFemale: 'Female',
        contactGenderX: 'X',
        contactBirthDateLabel: 'Birth Date',
        contactNotAdultMessage: 'Driver age is not a legal adult',
        contactSave: 'Save Info',
        viewLicense: 'View License',
        licenseSave: 'Save License',
        uploadLicenseLabel: 'Upload your drivers license (front and back) (Max 2)',
        updateContactOkMessage: 'Contact data updated',
        uploadLicenseRequiredLengthFilesMessage: 'Select front and back images for your driver\'s license',
        uploadLicenseFileOkMessage: 'Driver\'s license updated',
      },
      location: {
        title: 'Address',
        streetLabel: 'Street',
        streetPlaceHolder: 'Street name',
        externalNumber: 'External Number',
        externalNumberPlaceHolder: 'Address external number',
        district: 'District',
        districtPlaceHolder: 'District',
        zipCode: 'Zip Code',
        zipCodePlaceHolder: 'Zip code',
        phone: 'Phone',
        phonePlaceHolder: 'Add your phone number',
        email: 'Email',
        emailPlaceHolder: 'Add your email',
        locality: 'Locality',
        localityPlaceHolder: 'Locality',
        addressSave: 'Save Address',
        updatedContactAdressMessage: 'Contact address data updated',
      },
    },
  },
  home: {
    title: 'Home',
    panelLeft: {
      userLabel: 'User',
      userNewAccountLabel: 'New Account',
      userResetPasswordLabel: 'Reset Password',
      userEditAccountLabel: 'Edit Account',
      item: 'Loans (test)',
    },
    panelRight: {
      userLabel: 'User',
      userRegister: 'Register',
      sessionLabel: 'Session',
      sessionLogInLabel: 'Login',
      sessionLogOutLabel: 'Logut',
      lightThemeLabel: 'Light Theme',
      selectLanguageLabel: 'Language',
      sessionLogOutMessage: 'You\'ve logged out.',
      allowWebNotifications: 'Notifications (web)',
      notAllowedWebNotificationsMessage: 'If you don\'t allow notifications, you will be not be able to receive messages when you a get request for your car or when someone ask for yours',
    },
    topMenu: {
      offer: 'Offer',
      lend: 'Lend',
      howItWorks: 'How it works?',
      contact: 'Contact',
      notifications: 'Notifications',
    },
    main: {
      borrowCarLabel: 'Borrow a car',
      sloganLabel: 'Together we make the world more sustainable.',
      whatCarsLabel: 'What cars are available?',
      location: 'Location',
      date: 'Date',
      viewAvailabilityLabel: 'View Availability',
      flexible: 'Flexible',
      durable: 'Durable',
      transparent: 'Transparent',
      loanOfferLabel: 'Loan offer'
    },
  },
  car: {
    dashboard: {
      title: 'Dashboard',
      carList: 'Car list',
      additionalData: 'Additional data',
      aboutLender: 'About lender',
    },
    features: {
      titleShort: 'Features',
      titleDialog: 'Select Car Features',
      isUsed: 'Used?',
      name: 'Name',
      value: 'Value',
    },
    edit: {
      title: 'Car Data Edition',
      useMyCarLabel: 'I want to lend my car',
      manufacturerLabel: 'Manufacturer',
      modelLabel: 'Model',
      yearLabel: 'Year',
      priceLabel: 'Price',
      carIsEnsured: 'My car is all-risk insured',
      descriptionLabel: 'Description',
      carSave: 'Save Car Info',
      carImageUploadLabel: 'Car image',
      carImageUploadSave: 'Upload Car Image',
      carPlateUploadLabel: 'Plate image',
      carPlateUploadSave: 'Upload Car Plate',
      carFeaturePopupLabel: 'View Car Features',
      carImagesPhotoBrowserLabel: 'Show Car Images',
      carImageFileListLabel: 'Upload Images List',
      uploadCarImagesLabel: 'Upload your cars images (Max 6)',
      updatedCarMessage: 'Car data successfully updated',
      selectFileToUpload: 'Select a file to upload',
      carImageUploadedOkMessage: 'Car image successfully uploaded',
      carPlateUploadedOkMessage:'Car plate successfully uploaded',
      uploadCarImagesLimitMessage: 'You must select 6 images for your car',
      carImageListUploadedOkMessage: 'Car image list successfully uploaded',
      carIsUsedStatusMessage: 'Car is on a borrow request right now, can\'t be updated right now',
      carSize: 'Car Size',
      carEstimatedPrice: 'Car Estimated Price',
      suggestedLoanPrice: 'Suggested Loan Price',
      carMinPrice: 'Car Min Price',
      carMaxPrice: 'Car Max Price',
      minRecommendedPrice: 'Min Recommended Price',
      maxRecommendedPrice: 'Max Recommended Price',
      checkSizeMessage: 'Select a car size to determine suggested price',
      recommendedPriceMessage: 'Price must be between %% and %%, actual price: %%',
      freeKilometers: "Free Kilometers",
    },
  },
  loan: {
    title: 'Loan',
    lends: 'Lends',
    borrows: 'Borrows',
    tabs: [
      'Request',
      'Deciding',
      'Payment',
      //'Insurance',
      'Appointment',
      'Meeting',
      'Stopping',
      'Returning',
      'Rating',
      'Issues',
    ],
    dashboard: {
      carLoanTag: 'Label',
      lender: 'Lender',
      borrower: 'Borrower',
      startDate: 'Start Date',
      endDate: 'End Date',
      daysToBorrow: 'Days to Borrow',
      totalToPay: 'Total to Pay',
      discounts: 'Discounts',
      isGoingAbroad: 'Is Going Abroad',
      returningKilometers: 'Returning km\'s',
      currentKilometers: 'Current km\'s',
      pricePerDay: 'Price per Day',
    },
    request: {
      loanPeriod: 'Loan Period',
      loanRangeDatePlaceHolder: 'Select date range',
      onlyDriver: 'Only one driver',
      driveAbroad: 'Are you going to drive abroad? (+€1 per day)',
      extraDriverData: 'Extra driver data',
      messageRangeDate: 'Select a range of dates you will be traveling',
      onEmptyFormField: 'Please fill extra driver data, field: %%',
      confirmSaveRequest: 'Please, add your password to confirm that you agree to request for this car',
      messageSavedRequest: 'Lender will need to accept your request',
      onLoanPeriodAtLeast3Days: 'The loan period must be set at least 3 days from today',
      onLoadPeriodGreater30Days: 'Loan period can not be greater than 30 days',
    },
    deciding: {
      confirmCancel: 'Would you like to CANCEL to lend this car?',
      messageCancel: 'Loaner has declined to loan car. Canceling operations ...',
      confirmAccept: 'Please, add your password to confirm that you agree to borrow you car',
      messageAccept: 'You are required deposit of an insurance policy',
      hasDecided: 'has decided to ask for your car',
      loanInfo: 'Loan info',
      yourEarnings: 'Your earnings',
    },
    payment: {
      title: 'Your loan is almost ready, please proceed to pay to continue',
      goPay: 'Proceed to pay',
      popupTitle: 'Your payment',
      messageStripePaymentStatus: 'Your payment was %%',
      confirmCancel: 'Would you like to CANCEL payment for request car?',
      messageCancel: 'Lender has declined to pay for car. Canceling operations ...',
      messageAfterSucceded: 'Lender has been notified now to prepare insurance and you must expect an appointment to continue',
    },
    appointment: {
      labelData: 'Appoinment data',
      pleaseNote: 'Please note that',
      notRenting: 'De LeenAuto is not a rental service, instead is a borrow and loan service, so it is safe to make an external insurance with safety',
      noLawIssues: 'Also, the Borrower has been duly examined to ensure that he has not had contact with the justice system and/or has declared it, and that the Borrower has also declared compliance with the General Terms and Conditions of DeLeenAuto',
      agreedOnRequirements: 'Finally, the statement, requirements and all conditions of the external insurance coincide with the General Terms and Conditions of DeLeenAuto',
      insuranceFrom: ' De LeenAuto recommends do this through',
      insuranceHasBeenAdded: 'I have added insurance to my car',
      selectAppointmentDate: 'Select a date for meeting with your borrower',
      selectAppointmentPlace: 'Select a place for meeting with your borrower',
      confirmAddedInsurance: 'Confirm that you have added an insurance',
      noAppointmentSelected :'No appointment date was selected',
      noPlaceEntered: 'No place was entered',
      confirmMeeting:'Would you like to meet your borrower in %% at %%?',
      messageMeeting: 'Borrower will be notified to meet you in %% at %%',
    },
    meeting: {
      uploadCarLoanImagesLabel: 'Upload car\'s current images (Max 8)',
      uploadDriverImagesLabel: 'Upload driver\'s licenses (owner, borrower + extra) (Max 7)',
      currentFuelLevel: 'Current fuel level',
      currentKilometers: 'Current Kilometers',
      imageListLimitMessage: 'You must select %% images to upload',
      confirmStartLoan: 'Would you like to start the loan?',
      confirmEndLoan: 'Would you like to end the loan?',
      showCarImageLabel: 'Show car\'s images',
      showDriverImageLabel: 'Show drivers\' images',
      emptyTank: 'Fuel tank can not be empty',
      kilometersBelowOne: 'Kilometers can not be less than zero',
    },
    backAppointment: {
      selectAppointmentDate: 'Select a date for meeting with your lender',
      selectAppointmentPlace: 'Select a place for meeting with your lender',
      confirmMeeting:'Would you like to meet your lender in %% at %%?',
      messageMeeting: 'Lender will be notified to meet you in %% at %%',
    },
    issue: {
      checkLabel: 'Did you have any issue?',
      description: 'Describe your issue',
      placeholder: 'Any issue?',
    },
    rating: {
      yourRatingLabel: 'Your rating',
      yourFeedbackLabel: 'Your feedback',
      sendFeedback: 'Send Feedback',
      textPlaceHolder: 'Your feedback here',
      chooseStarMessage:'Select a star for rating',
      editorMessage: 'Please, add some feedback',
      afterSaveMessage: 'Your feedback has been added',
      starLabel: '%% rating',   /* User:lender or borrower rating */
      userLabel: '%% feedback', /* User:lender or borrower feedback */
    },
    statusLabel: {
      1: 'Requested',
      2: 'Acepted',
      3: 'Paid',
      4: 'Agreed meet',
      5: 'Loaned',
      6: 'Agreed return',
      7: 'Returned',
      8: 'Rated',
      9: 'Finished',
      10: 'Finished with issues',
      12: 'Canceled by Lender',
      13: 'Canceled by Borrower',
    },
  },
  admin: {
    title: 'Admin Panel',
    sections: {
      carPricing: 'Smart Pricing',
      carPricingEdit: 'Pricing Edition',
      carLoanGrid: 'Current Open Loans',
    },
  }
};

export default dictionaryEN;
