import { createStore } from 'framework7';
import { http } from '../api/config.js';

const carLoan = createStore({
  state: {
    item: {},
    list: [],
  },
  actions: {
//Mutations
    set_item({ state }, item) {
      state.item = item;
    },
//End mutations

    async getAllCarLoans({ dispatch }, filter) {
      let response = [];
      let result = {};

      result = await http.get(`car/loan/list/all`);
      if (result.status === 200) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },

    async getLenderList({ dispatch }, filter) {
      let response = [];
      let result = {};

      result = await http.get(`car/loan/list/lender`);
      if (result.status === 200) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },

    async getBorrowerList({ dispatch }, filter) {
      let response = [];
      let result = {};

      result = await http.get(`car/loan/list/borrower`);
      if (result.status === 200) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },
    async getSingleIssue({ dispatch }, loanId) {
      let response = [];
      let result = {};

      result = await http.get(`loan/issue/single/${loanId}`);
      if (result.status === 200) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },

    async getIssueByLoan({ dispatch }, loanId) {
      let response = [];
      let result = {};

      result = await http.get(`loan/issue/data/${loanId}`);
      if (result.status === 200) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },

    async getRatingForLender({ dispatch }, { referenceId, fromId }){
      let response = [];
      let result = {};

      result = await http.get(`rating/lender/${referenceId}/${fromId}`);
      if (result.status === 200) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },

    async getRatingForBorrower({ dispatch }, { referenceId, fromId }){
      let response = [];
      let result = {};

      result = await http.get(`rating/borrower/${referenceId}/${fromId}`);
      if (result.status === 200) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },

    async create({ dispatch }, data) {
      let response = [];
      let result = {};

      result = await http.post(`car/loan/create`, data, { headers: {'content-type': 'application/x-www-form-urlencoded'} });
      if (result.status === 201) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },

    async lenderAccepts({ dispatch }, data) {
      let response = [];
      let result = {};

      result = await http.post(`loan/lender/accepts`, data, { headers: {'content-type': 'application/x-www-form-urlencoded'} });
      if (result.status === 200) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },

    async lenderCancels({ dispatch }, data) {
      let response = [];
      let result = {};

      result = await http.post(`loan/lender/cancels`, data, { headers: {'content-type': 'application/x-www-form-urlencoded'} });
      if (result.status === 200) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },

    async borrowerPays({ dispatch }, data) {
      let response = [];
      let result = {};

      result = await http.post(`loan/borrower/pays`, data, { headers: {'content-type': 'application/x-www-form-urlencoded'} });
      if (result.status === 200) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },

    async borrowerCancels({ dispatch }, data) {
      let response = [];
      let result = {};

      result = await http.post(`loan/borrower/cancels`, data, { headers: {'content-type': 'application/x-www-form-urlencoded'} });
      if (result.status === 200) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },

    async createPaymentIntent({ dispatch }, data) {
      let response = [];
      let result = {};

      result = await http.post(`loan/create/payment`, data, { headers: {'content-type': 'application/x-www-form-urlencoded'} });
      if (result.status === 201) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },

    async cancelPaymentIntent({ dispatch }, data) {
      let response = [];
      let result = {};

      result = await http.post(`loan/cancel/payment`, data, { headers: {'content-type': 'application/x-www-form-urlencoded'} });
      if (result.status === 200) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },

    async setAppointment({ dispatch }, data) {
      let response = [];
      let result = {};

      result = await http.post(`loan/set/appointment`, data, { headers: {'content-type': 'application/x-www-form-urlencoded'} });
      if (result.status === 200) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },

    async setReturnAppointment({ dispatch }, data) {
      let response = [];
      let result = {};

      result = await http.post(`loan/back/appointment`, data, { headers: {'content-type': 'application/x-www-form-urlencoded'} });
      if (result.status === 200) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },

    async saveLoanEventStart({ dispatch }, data) {
      let response = [];
      let result = {};

      result = await http.post(`loan/start`, data, { headers: {'content-type': 'application/x-www-form-urlencoded'} });
      if (result.status === 200) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },

    async saveLoanEventEnd({ dispatch }, data) {
      let response = [];
      let result = {};
  
      result = await http.post(`loan/end`, data, { headers: {'content-type': 'application/x-www-form-urlencoded'} });
      if (result.status === 200) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },

    async createLoanIssue({ dispatch }, data) {
      let response = [];
      let result = {};

      result = await http.post(`loan/issue/create`, data, { headers: {'content-type': 'application/x-www-form-urlencoded'} });
      if (result.status === 201) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },

    async ratingLender({ dispatch }, data) {
      let response = [];
      let result = {};
  
      result = await http.post(`loan/lender/rating`, data, { headers: {'content-type': 'application/x-www-form-urlencoded'} });
      if (result.status === 201) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },

    async ratingBorrower({ dispatch }, data) {
      let response = [];
      let result = {};
  
      result = await http.post(`loan/borrower/rating`, data, { headers: {'content-type': 'application/x-www-form-urlencoded'} });
      if (result.status === 201) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },
  },

  getters: {
    Item({ state }) {
      return state.item;
    }
  },
});

export default carLoan;
