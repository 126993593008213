import UserEditPage from '../../pages/user/edit.f7.html';
import UserNewPage from '../../pages/user/new.f7.html';
// import UserResetPage from '../../pages/user/reset-data.f7.html';
import UserLoginPage from '../../pages/user/login.f7.html';
import UserEnablePage from '../../pages/user/enable.f7.html';

import user from '../stores/user.js';
import Check from '../controllers/check.js';

function checkAuth( { resolve } ) {
  const router = this;
  if (user.getters.isLogged.value) {
    router.navigate('/', { clearPreviousHistory : true });
  }
  else {
    if (router.currentRoute.name !== "user-login") {
      resolve('/user-login/');
    }
  }
}

const users = [
  {
    name: 'user-new',
    path: '/user-new/',
    component: UserNewPage,
  },
  {
    name: 'user-enable',
    path: '/user-enable/:userId/:userToken/',
    component: UserEnablePage,
  },
  {
    name: 'user-login',
    path: '/user-login/',
    component: UserLoginPage,
    beforeEnter: checkAuth,
  },
  {
    name: 'user-edit',
    path: '/user-edit/',
    component: UserEditPage,
    allowedRoles: [1, 4],
    beforeEnter: [Check.authorization, Check.permission],
  },
  // {
  //   name: 'user-reset',
  //   path: '/user-reset/',
  //   popup: {
  //     component: UserResetPage,
  //   },
  // },
];

export default users;
