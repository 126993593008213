import { createStore } from 'framework7';
import { http } from './../api/config.js';

const file = createStore({
  state: {
    item: {},
    list: [],
  },
  actions: {
//Mutations
    insert_list_data({ state }, data) {
      state.list = data;
    },
    set_item({ state}, data) {
      state.item = data;
    },
//End mutations
    async getFileItem({ dispatch }, param) {
      let response = [];
      let result = {};

      result = await http.get(`file/get/${param}`);
      if (result.status === 200) {
        response = result.data;
        response.FilePath = `${http.defaults.baseURL}/${response.FilePath}/${response.FileName}`;
        dispatch('set_item', response);
        return response;
      }
      else {
        return result;
      }
    },
    async getUserAvatar({ dispatch }, param) {
      let response = [];
      let result = {};

      result = await http.get(`file/user/avatar/${param}`);
      if (result.status === 200) {
        response = result.data[0];
        dispatch('set_item', response);
        return response;
      }
      else {
        return result;
      }
    },
    async getDriverLicense({ dispatch }, param) {
      let response = [];
      let result = {};

      result = await http.get(`file/driver/license/${param}`);
      if (result.status === 200) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },
    async getCarImage({ dispatch }, param) {
      let response = [];
      let result = {};

      result = await http.get(`file/car/single/${param}`);
      if (result.status === 200) {
        response = result.data[0];
        dispatch('set_item', response);
        return response;
      }
      else {
        return result;
      }
    },
    async getCarPlate({ dispatch }, param) {
      let response = [];
      let result = {};

      result = await http.get(`file/car/plate/${param}`);
      if (result.status === 200) {
        response = result.data[0];
        dispatch('set_item', response);
        return response;
      }
      else {
        return result;
      }
    },
    async getCarImageList({ dispatch }, param) {
      let response = [];
      let result = {};

      result = await http.get(`file/car/images/${param}`);
      if (result.status === 200) {
        response = result.data;
        dispatch('insert_list_data', response);
        return response;
      }
      else {
        return result;
      }
    },
    async getLoanCarImages({ dispatch }, param) {
      let response = [];
      let result = {};

      result = await http.get(`file/loan-car/images/${param}`);
      if (result.status === 200) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },
    async getLoanDriverImages({ dispatch }, param) {
      let response = [];
      let result = {};

      result = await http.get(`file/loan-driver/images/${param}`);
      if (result.status === 200) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },
    async getReturnLoanCarImages({ dispatch }, param) {
      let response = [];
      let result = {};

      result = await http.get(`file/return-car/images/${param}`);
      if (result.status === 200) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },
    async getReturnLoanDriverImages({ dispatch }, param) {
      let response = [];
      let result = {};

      result = await http.get(`file/return-driver/images/${param}`);
      if (result.status === 200) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },
    async getCarLoanIssueImages({ dispatch }, param) {
      let response = [];
      let result = {};

      result = await http.get(`file/return-driver/images/${param}`);
      if (result.status === 200) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },
    async uploadUserAvatar({ dispatch }, item) {
      let response = [];
      let result = {};

      result = await http.post(`file/upload/user/avatar`, item, { headers: {'content-type': 'multipart/form-data'} });
      if (result.status === 201) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },
    async uploadDriverLicense({ dispatch }, item) {
      let response = [];
      let result = {};

      result = await http.post(`file/upload/driver/license`, item, { headers: {'content-type': 'multipart/form-data'} });
      if (result.status === 201) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },
    async uploadCarImage({ dispatch }, item) {
      let response = [];
      let result = {};

      result = await http.post(`file/upload/car/single`, item, { headers: {'content-type': 'multipart/form-data'} });
      if (result.status === 201) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },
    async uploadCarPlate({ dispatch }, item) {
      let response = [];
      let result = {};

      result = await http.post(`file/upload/car-plate/image`, item, { headers: {'content-type': 'multipart/form-data'} });
      if (result.status === 201) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },
    async uploadCarImages({ dispatch }, item) {
      let response = [];
      let result = {};

      result = await http.post(`file/upload/car/images`, item, { headers: {'content-type': 'multipart/form-data'} });
      if (result.status === 201) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },
    async uploadLoanCarImages({ dispatch }, items) {
      let response = [];
      let result = {};

      result = await http.post(`file/new/loan-car/images`, items, { headers: {'content-type': 'multipart/form-data'} });
      if (result.status === 201) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },
    async uploadLoanDriverImages({ dispatch }, items) {
      let response = [];
      let result = {};

      result = await http.post(`file/new/loan-driver/images`, items, { headers: {'content-type': 'multipart/form-data'} });
      if (result.status === 201) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },
    async uploadLoanReturnCarImages({ dispatch }, items) {
      let response = [];
      let result = {};

      result = await http.post(`file/new/return-car/images`, items, { headers: {'content-type': 'multipart/form-data'} });
      if (result.status === 201) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },
    async uploadLoanReturnDriverImages({ dispatch }, items) {
      let response = [];
      let result = {};

      result = await http.post(`file/new/return-driver/images`, items, { headers: {'content-type': 'multipart/form-data'} });
      if (result.status === 201) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },
    async uploadLoanIssueImages({ dispatch }, items) {
      let response = [];
      let result = {};

      result = await http.post(`file/new/loan-issue/images`, items, { headers: {'content-type': 'multipart/form-data'} });
      if (result.status === 201) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },
  },
  // getters: {
  //   Item({ state }) {
  //     return state.name;
  //   }
  // },
});

export default file;
