const dictionaryDE = {
  originalWriting: 'Deutsch',
  general: {
    user: 'Benutzer',
    email: 'E-mail',
    password: 'Passwort',
    close: 'Schließen',
    save: 'Speichern',
    done: 'Fertig',
    accept: 'Akzeptieren',
    cancel: 'Abbrechen',
    decline: 'Ablehnen',
    cut: 'Schneiden',
    browse: 'Durchsuchen',
    chooseFileLabel: 'Datei auswählen...',
    selectedCountLabel: 'Dateien ausgewählt',
    from: 'von',
    perDay: 'pro Tag',
    select: 'Auswählen',
    owner: 'Besitzer',
    details: 'Details',
    sizeList:[
      'Keine',
      'Klein',
      'Mittel',
      'Groß'
    ],
    options: 'Optionen',
    status: 'Status',
    pay: 'Bezahlen',
    edit: 'Bearbeiten',
    from: 'Von',
    to: 'Bis',
    date: 'Datum',
    place: 'Ort',
    empty: 'Leer',
    full: 'Voll',
    go: 'Go',
    uploadImagesLabel: 'Bilder hochladen (Max %%)',
    uploadListLimitMessage: 'Sie müssen %% Bilder zum Hochladen auswählen',
  },
  user: {
    login: {
      title: 'Anmelden',
      userMailLabel: 'Benutzer oder E-Mail',
      userMailPlaceHolder: 'Benutzername oder E-Mail',
      userPasswordLabel: 'Passwort',
      userPasswordPlaceholder: 'Passwort',
      loginButtonLabel: 'Anmelden',
      newUserLabel: 'Neues Konto',
      successfulLabel: 'Sie sind derzeit angemeldet'
    },
    newAccount: {
      titleDialog: 'Neuen Benutzer registrieren',
      titleForm: 'Neuer Benutzer',
      userNameLabel: 'Benutzer',
      userNamePlaceHolder: 'Benutzername (mindestens 8 Zeichen)',
      userEmailLabel: 'E-Mail',
      userEmailPlaceholder: 'E-Mail',
      userPasswordLabel: 'Passwort',
      userPasswordAllowedCharsMessage: 'Erlaubte Zeichen: Großbuchstaben, Kleinbuchstaben, Zahlen und @$!%*?&',
      userPasswordPlaceholder: 'Passwort (mindestens 8 Zeichen)',
      userPasswordConfirmLabel: 'Passwort bestätigen',
      userPasswordConfirmPlaceHolder: 'Passwort bestätigen',
      userAgreementCheckbox: 'Ich akzeptiere die Vereinbarung voll und ganz',
      userAgreementViewDoc: 'Vereinbarung anzeigen',
      userMissingFieldsMessage: 'Einige Felder fehlen, bitte füllen Sie die Pflichtfelder aus.',
      userMinLengthMessage: 'Benutzername muss mindestens 8 Zeichen lang sein.',
      userEmailValidMessage: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
      userPasswordValidationsMessage: 'Passwort ist nicht stark genug. Muss mindestens 8 Zeichen haben. <br/> Muss mindestens 1 Kleinbuchstaben enthalten.<br/> Muss mindestens 1 Großbuchstaben enthalten.<br/> Muss eine Zahl enthalten.<br/> Es muss mindestens eine enthalten dieser Zeichen: @$!%*?&',
      userPasswordMismatchMessage: 'Passwort stimmt nicht überein.',
      userAcceptAgreementMessage: 'Sie müssen unsere Vertragsbedingungen gelesen und akzeptiert haben.',
      userSuccesfullyCreatedMessage: 'Benutzer wurde erstellt. Bitte öffnen Sie Ihr E-Mail-Konto und aktivieren Sie es.',
    },
    edit: {
      title: 'Benutzerkonto-Edition',
      tabs: [
        'Benutzerkonto',
        'Personal',
        'Standort',
        'Fahrzeugdaten',
      ],
      account: {
        avatarSelection: 'Avatar-Edition/-Auswahl',
        avatarUploadImage: 'Bild hochladen',
        avatarSave: 'Speichern',
        uploadAvatarOkMessage: 'Avatar-Bild erfolgreich aktualisiert',
      },
      personal: {
        title: 'Personenbezogene Daten',
        contactFirstName: 'Vorname',
        contactLastName: 'Nachname',
        contactGenderLabel: 'Geschlecht',
        contactGenderMale: 'Männlich',
        contactGenderFemale: 'Weiblich',
        contactGenderX: 'X',
        contactBirthDateLabel: 'Geburtsdatum',
        contactNotAdultMessage: 'Das Alter des Fahrers entspricht nicht dem eines volljährigen Erwachsenen',
        contactSave: 'Info speichern',
        viewLicense: 'Lizenz anzeigen',
        licenseSave: 'Lizenz speichern',
        uploadLicenseLabel: 'Laden Sie Ihren Führerschein hoch (Vorder- und Rückseite) (Max 2)',
        updateContactOkMessage: 'Kontaktdaten aktualisiert',
        uploadLicenseRequiredLengthFilesMessage: 'Wählen Sie Bilder für die Vorder- und Rückseite Ihres Führerscheins aus',
        uploadLicenseFileOkMessage: 'Führerschein aktualisiert',
      },
      location: {
        title: 'Adresse',
        streetLabel: 'Straße',
        streetPlaceHolder: 'Straßenname',
        externalNumber: 'Externe Nummer',
        externalNumberPlaceHolder: 'Externe Nummer adressieren',
        district: 'Bezirk',
        districtPlaceHolder: 'Bezirk',
        zipCode: 'Postleitzahl',
        zipCodePlaceHolder: 'Postleitzahl',
        phone: 'Telefon',
        phonePlaceHolder: 'Fügen Sie Ihre Telefonnummer hinzu',
        email: 'Email',
        emailPlaceHolder: 'Fügen Sie Ihre E-Mail-Adresse hinzu',
        locality: 'Ort',
        localityPlaceHolder: 'Locality',
        addressSave: 'Adresse speichern',
        updatedContactAdressMessage: 'Kontaktadressdaten aktualisiert',
      },
    },
  },
  home: {
    title: 'Home',
    panelLeft: {
      userLabel: 'Benutzer',
      userNewAccountLabel: 'Neues Konto',
      userResetPasswordLabel: 'Passwort zurücksetzen',
      userEditAccountLabel: 'Konto bearbeiten',
      item: 'Kredite (Test)',
    },
    panelRight: {
      userLabel: 'Benutzer',
      userRegister: 'Registrieren',
      sessionLabel: 'Sitzung',
      sessionLogInLabel: 'Anmelden',
      sessionLogOutLabel: 'Abmelden',
      lightThemeLabel: 'Helles Thema',
      selectLanguageLabel: 'Sprache',
      sessionLogOutMessage: 'Sie haben sich abgemeldet.',
      allowWebNotifications: 'Notifications (web)',
      notAllowedWebNotificationsMessage: 'Wenn du Benachrichtigungen nicht erlaubst, wirst du keine Nachrichten erhalten können, wenn du eine Anfrage für dein Auto bekommst oder wenn jemand nach deinem fragt',
    },
    topMenu: {
      offer: 'Angebot',
      lend: 'leihen',
      howItWorks: 'Wie funktioniert es?',
      contact: 'Kontakt',
      notifications: 'Notifications',
    },
    main: {
      borrowCarLabel: 'Ein Auto ausleihen',
      sloganLabel: 'Gemeinsam machen wir die Welt nachhaltiger.',
      whatCarsLabel: 'Welche Autos sind verfügbar?',
      location: 'Standort',
      date: 'Datum',
      viewAvailabilityLabel: 'Verfügbarkeit anzeigen',
      flexible: 'Flexibel',
      durable: 'Dauerhaft',
      transparent: 'Transparent',
      loanOfferLabel: 'Kreditangebot'
    },
  },
  car: {
    dashboard: {
      title: 'Armaturenbrett',
      carList: 'Auto Liste',
      additionalData: 'zusätzliche Daten',
      aboutLender: 'About lender',
    },
    features: {
      titleShort: 'Features',
      titleDialog: 'Fahrzeugfunktionen auswählen',
      isUsed: 'Gebraucht?',
      name: 'Name',
      value: 'Wert',
    },
    edit: {
      title: 'Autodaten-Edition',
      useMyCarLabel: 'Ich möchte mein Auto verleihen',
      manufacturerLabel: 'Hersteller',
      modelLabel: 'Modell',
      yearLabel: 'Jahr',
      priceLabel: 'Preis',
      carIsEnsured: 'Mein Auto ist vollkaskoversichert',
      descriptionLabel: 'Beschreibung',
      carSave: 'Fahrzeuginformationen speichern',
      carImageUploadLabel: 'Auto-Bild',
      carImageUploadSave: 'Auto-Bild hochladen',
      carPlateUploadLabel: 'Kennzeichen-Bild',
      carPlateUploadSave: 'Autokennzeichen hochladen',
      carFeaturePopupLabel: 'Fahrzeugmerkmale anzeigen',
      carImagesPhotoBrowserLabel: 'Autobilder anzeigen',
      carImageFileListLabel: 'Bilderliste hochladen',
      uploadCarImagesLabel: 'Laden Sie Bilder Ihres Autos hoch (Max 6)',
      updatedCarMessage: 'Autodaten erfolgreich aktualisiert',
      selectFileToUpload: 'Wählen Sie eine Datei zum Hochladen aus',
      carImageUploadedOkMessage: 'Autobild erfolgreich hochgeladen',
      carPlateUploadedOkMessage: 'Autokennzeichen erfolgreich hochgeladen',
      uploadCarImagesLimitMessage: 'Sie müssen 6 Bilder für Ihr Auto auswählen',
      carImageListUploadedOkMessage: 'Liste der Autobilder erfolgreich hochgeladen',
      carIsUsedStatusMessage: 'Das Auto ist derzeit auf einer Ausleihanfrage und kann momentan nicht aktualisiert werden',
      carSize: 'Autogröße',
      carEstimatedPrice: 'Geschätzter Autopreis',
      suggestedLoanPrice: 'Vorgeschlagener Kreditpreis',
      carMinPrice: 'Mindestpreis für Autos',
      carMaxPrice: 'Auto Höchstpreis',
      minRecommendedPrice: 'Mindestpreis',
      maxRecommendedPrice: 'Maximal empfohlener Preis',
      checkSizeMessage: 'Wählen Sie eine Autogröße, um den empfohlenen Preis zu bestimmen',
      recommendedPriceMessage: 'Der Preis muss zwischen %% und %% liegen, aktueller Preis: %%',
      freeKilometers: "Freikilometer",
    },
  },
  loan: {
    title: 'Darlehen',
    lends: 'Verleiht',
    borrows: 'Leiht aus',
    tabs: [
      'Anfrage',
      'Entscheiden',
      'Zahlung',
      //'Versicherung',
      'Termin',
      'Treffen',
      'Anhalten',
      'Rückkehr',
      'Bewertung',
      'Issues',
    ],
    dashboard: {
    carLoanTag: 'Label',
      lender: 'Verleiher',
      borrower: 'Entleiher',
      startDate: 'Startdatum',
      endDate: 'Enddatum',
      daysToBorrow: 'Tage zum Ausleihen',
      totalToPay: 'Zu zahlender Gesamtbetrag',
      discounts: 'Rabatte',
      isGoingAbroad: 'Geht ins Ausland',
      returningKilometers: 'Zurückgebrachte km',
      currentKilometers: 'Aktuelle km',
      pricePerDay: 'Preis pro Tag',
    },
    request: {
      loanPeriod: 'Kreditlaufzeit',
      loanRangeDatePlaceHolder: 'Wählen Sie den Datumsbereich',
      onlyDriver: 'Nur ein Fahrer',
      driveAbroad: 'Werden Sie im Ausland fahren? (+€1 pro Tag)',
      extraDriverData: 'Zusätzliche Fahrerdaten',
      messageRangeDate: 'Wählen Sie einen Reisezeitraum',
      onEmptyFormField: 'Bitte füllen Sie die Daten des zusätzlichen Fahrers aus, Feld: %%',
      confirmSaveRequest: 'Bitte geben Sie Ihr Passwort ein, um zu bestätigen, dass Sie die Anfrage für dieses Auto akzeptieren',
      messageSavedRequest: 'Der Verleiher muss Ihre Anfrage akzeptieren',
      onLoanPeriodAtLeast3Days: 'Die Leihdauer muss mindestens 3 Tage ab heute betragen',
      onLoadPeriodGreater30Days: 'Die Leihdauer darf nicht länger als 30 Tage sein',
    },
    deciding: {
      confirmCancel: 'Möchten Sie das Ausleihen dieses Autos ABBRECHEN?',
      messageCancel: 'Der Verleiher hat abgelehnt, das Auto auszuleihen. Vorgänge werden abgebrochen ...',
      confirmAccept: 'Bitte geben Sie Ihr Passwort ein, um zu bestätigen, dass Sie das Auto ausleihen möchten.',
      messageAccept: 'Sie müssen eine Versicherungspolice hinterlegen.',
      hasDecided: 'hat beschlossen, nach Ihrem Auto zu fragen',
      loanInfo: 'Darlehensinfo',
      yourEarnings: 'Ihr Verdienst',
    },
    payment: {
      title: 'Ihr Darlehen ist fast bereit, bitte fahren Sie mit der Zahlung fort, um fortzufahren',
      goPay: 'Zur Zahlung fortfahren',
      popupTitle: 'Ihre Zahlung',
      messageStripePaymentStatus: 'Ihre Zahlung war %%',
      confirmCancel: 'Möchten Sie die Zahlung für das angeforderte Auto STORNIEREN?',
      messageCancel: 'Der Verleiher hat die Zahlung für das Auto abgelehnt. Vorgänge werden abgebrochen ...',
      messageAfterSucceded: 'Der Verleiher wurde benachrichtigt, um die Versicherung vorzubereiten, und Sie müssen nun einen Termin erwarten, um fortzufahren',
    },
    appointment: {
      labelData: 'Termindaten',
      pleaseNote: 'Bitte beachten Sie, dass',
      notRenting: 'De LeenAuto ist kein Mietservice, sondern ein Verleihservice. Daher ist es ratsam, eine externe Versicherung abzuschließen',
      noLawIssues: 'Der Entleiher wurde ordnungsgemäß überprüft, um sicherzustellen, dass er keinen Kontakt mit dem Justizsystem hatte und/oder dies angegeben hat. Außerdem hat der Entleiher die Einhaltung der Allgemeinen Geschäftsbedingungen von DeLeenAuto erklärt',
      agreedOnRequirements: 'Schließlich stimmen die Angaben, Anforderungen und alle Bedingungen der externen Versicherung mit den Allgemeinen Geschäftsbedingungen von DeLeenAuto überein',
      insuranceFrom: ' De LeenAuto empfiehlt, dies durchzuführen über',
      insuranceHasBeenAdded: 'Ich habe eine Versicherung für mein Auto hinzugefügt',
      selectAppointmentDate: 'Wählen Sie ein Datum für das Treffen mit Ihrem Entleiher',
      selectAppointmentPlace: 'Wählen Sie einen Ort für das Treffen mit Ihrem Entleiher',
      confirmAddedInsurance: 'Bestätigen Sie, dass Sie eine Versicherung hinzugefügt haben',
      noAppointmentSelected :'Kein Termin wurde ausgewählt',
      noPlaceEntered: 'Kein Ort wurde eingegeben',
      confirmMeeting:'Möchten Sie Ihren Entleiher am %% um %% treffen?',
      messageMeeting: 'Der Entleiher wird benachrichtigt, Sie am %% um %% zu treffen',
    },
    meeting: {
      uploadCarLoanImagesLabel: 'Laden Sie aktuelle Bilder des Autos hoch (max. 8)',
      uploadDriverImagesLabel: 'Laden Sie Führerscheine hoch (Besitzer, Entleiher + zusätzlich) (max. 7)',
      currentFuelLevel: 'Aktueller Kraftstoffstand',
      currentKilometers: 'Aktueller Kilometerstand',
      imageListLimitMessage: 'Sie müssen %% Bilder zum Hochladen auswählen',
      confirmStartLoan: 'Möchten Sie den Verleih starten?',
      confirmEndLoan: 'Möchten Sie den Verleih beenden?',
      showCarImageLabel: 'Autobilder anzeigen',
      showDriverImageLabel: 'Fahrerbilder anzeigen',
      emptyTank: 'Der Tank darf nicht leer sein',
      kilometersBelowOne: 'Kilometerstand darf nicht unter null liegen',
    },
    backAppointment: {
      selectAppointmentDate: 'Wählen Sie ein Datum für das Treffen mit Ihrem Verleiher aus',
      selectAppointmentPlace: 'Wählen Sie einen Ort für das Treffen mit Ihrem Verleiher aus',
      confirmMeeting:'Möchten Sie sich mit Ihrem Verleiher am %% um %% treffen?',
      messageMeeting: 'Der Verleiher wird benachrichtigt, Sie am %% um %% zu treffen',
    },
    issue: {
      checkLabel: 'Did you have any issue?',
      description: 'Describe your issue',
      placeholder: 'Any issue?',
    },
    rating: {
      yourRatingLabel: 'Your rating',
      yourFeedbackLabel: 'Your feedback',
      sendFeedback: 'Send Feedback',
      textPlaceHolder: 'Your feedback here',
      chooseStarMessage:'Select a star for rating',
      editorMessage: 'Please, add some feedback',
      afterSaveMessage: 'Your feedback has been added',
      starLabel: '%% rating',   /* User:lender or borrower rating */
      userLabel: '%% feedback', /* User:lender or borrower feedback */
    },
    statusLabel: {
      1: 'Angefragt',
      2: 'Akzeptiert',
      3: 'Bezahlt',
      4: 'Vereinbartes Treffen',
      5: 'Ausgeliehen',
      6: 'Vereinbarte Rückgabe',
      7: 'Zurückgegeben',
      8: 'Bewertet',
      9: 'Fertig',
      10: 'Finished with issues',
      12: 'Vom Verleiher storniert',
      13: 'Vom Entleiher storniert',
    },
  },
  admin: {
    title: 'Admin-Bereich',
    sections: {
      carPricing: 'Intelligente Preisgestaltung',
      carPricingEdit: 'Preisgestaltung Edition',
      carLoanGrid: 'Current Open Loans',
    },
  }
};

export default dictionaryDE;
